<template lang="pug">
modal(size='xl' @close='$emit("close")')
  generic-form(:endpoint='endpoint' updateOnSubmitSuccess='SelectProductSupplierForm' hideNotifications @submitSuccess='closeAfterSubmit && $emit("close")')
    template(#default='{ notifications, submit, config, configMap }')
      external-project-item-form(
        v-if='config'
        v-model:price='price'
        v-model:isNet='isNet'
        :contextId='contextId'
        :isModeEdit='isModeEdit'
        :disableCommonFields='disableCommonFields'
        :disablePriceFields='disablePriceFields'
        :submit='submit'
      )
        template(#header)
          div(class='flex items-center gap-3')
            span {{ $t('Edit external product') }}
            pill(v-if='configMap.IsVerified.value === "true"' type='success' icon='check' outlined) {{ $t('verified') }}
            pill(v-else type='faded' icon='info' outlined) {{ $t('not verified') }}
        template(#actions)
          btn(:data-tooltip='$t("Save")' icon='save' secondary :disabled='disableCommonFields' @click='submit("EditExternalInquiryListItemForm/UpdateInquiryListItem")') {{ $t('Save') }}
          form-action(
            v-if='$permissions.canAccessAdminMenu'
            type='secondary'
            primary
            name='UpdateInquiryListItem'
            :disabled='disableCommonFields'
            formActionName='EditExternalInquiryListItemForm/UpdateInquiryListItem'
            @click='closeAfterSubmit = true'
          )
            template(#inner) {{ $t('Save & Close') }}
        template(v-if='$permissions.canAccessAdminMenu')
          div(class='highlight-nuucon -mt-1 mb-4')
            div
              b {{ $t('Current Supplier & Purchasing Price') }}:
            span {{ showPurchasingPriceAndSupplier(configMap) }}
            btn(plain tiny class='ml-2' icon='custom-edit' @click='openFormSelectProductSupplierModal(configMap.BrandSuppliers.value)')
          form-checkbox(name='IsVerified' class='highlight-nuucon')
            b {{ $t('verified') }}
          notification-list(v-if='notifications' class='mb-2' :items='notifications')
</template>

<script lang="ts">
import { type IGenericFormConfigMap } from '@/components/GenericForm/types'
import { useInquiryStatus } from '@/pages/inquiries/composables'
import { type InquiryStatus } from '@/pages/inquiries/types'
import { useModals } from '@/plugins/Modals'
import { usePermissions } from '@/plugins/Permissions'
import { formatPercents, formatPrice } from '@/utilities'
import { computed, defineComponent, ref, type PropType } from 'vue'

const FormEditExternalInquiryItem = defineComponent({
  inheritAttrs: false,
  props: {
    contextId: { type: String, required: true },
    productId: { type: String, required: true },
    inquiryStatus: { type: String as PropType<InquiryStatus>, required: true },
  },
  setup(props) {
    const $permissions = usePermissions()
    const $modals = useModals()
    const discount = ref<string | undefined>('')
    const isNet = ref<'true' | 'false'>('false')
    const price = ref<number>(0)
    const closeAfterSubmit = ref(false)
    const { isInquiryStatusDrafted, isInquiryStatusAfterOffered } = useInquiryStatus(props.inquiryStatus)
    return {
      openFormSelectProductSupplierModal: (supplierId: string) => {
        $modals.open('form-select-product-supplier', {
          inquiryId: props.contextId,
          productId: props.productId,
          priceNet: price.value,
          preSelectedSupplierId: supplierId,
          inquiryStatus: props.inquiryStatus,
        })
      },
      showPurchasingPriceAndSupplier: (configMap: IGenericFormConfigMap) => {
        const purchasingPrice = configMap.PurchasingPrice!.value as number
        const priceNet = configMap.IsNet.value
          ? (configMap.Price!.value as number)
          : (configMap.Price!.value as number) / 1.19
        const discount = 1 - purchasingPrice / priceNet
        const selectedBrandSupplier = configMap.BrandSuppliers.options!.find(
          (x: any) => x.value === configMap.BrandSuppliers.value,
        )
        if (selectedBrandSupplier) {
          return `${selectedBrandSupplier.label} (${formatPrice(purchasingPrice)}, ${formatPercents(discount)})`
        } else {
          return `${configMap.BrandSuppliers.value || 'no supplier selected'} (${formatPrice(purchasingPrice)}, ${formatPercents(discount)})`
        }
      },
      closeAfterSubmit,
      isInquiryStatusDrafted,
      disableCommonFields: computed(() => !$permissions.canAccessAdminMenu && !isInquiryStatusDrafted.value),
      disablePriceFields: computed(
        () => (!$permissions.canAccessAdminMenu && !isInquiryStatusDrafted.value) || isInquiryStatusAfterOffered.value,
      ),
      formatPrice,
      isNet,
      price,
      discount,
      endpoint: computed(() => `/_/EditExternalInquiryItem?&productId=${props.productId}&inquiryId=${props.contextId}`),
      isModeEdit: computed(() => !!props.productId),
    }
  },
})
export default FormEditExternalInquiryItem
</script>
